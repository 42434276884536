<template>
<div class="relative" style="height: 100vh;" v-if="isMobile === false">
  <aside
    class="z-20 w-64 sidebar overflow-y-auto overflow-x-auto fixed shadow-sm h-full bg-white dark:bg-gray-800"
  >
    <div
      class="text-gray-500 bar dark:text-gray-400 h-full flex flex-col justify-between"
    >
      <div class="list">
        <div
          class="text-lg logo flex justify-center items-center font-bold text-gray-800 dark:text-gray-200"
          href="#"
        >
          <router-link to="/" class="flex justify-center items-center">
            <img
              class="logo"
              src="../../assets/images/LauchScreen.png"
              alt="Logo"
            />
          </router-link>
        </div>
        <template v-if="Labels.length > 0">
          <ul class="mt-6">
            <template v-for="(item, index) in Labels" :key="index">
              <li v-if="item.show != false" class="relative px-6 py-3 my-2" :class="item.path === route.path && isMobile === true ? `border-b-4 border-pink-900` : ''">
                <span
                  class="bg-primary hover:text-primary"
                  aria-hidden="true"
                  v-if="item.path == route.path"
                ></span> 
                <a
                  :class="`inline-flex items-center w-full select-none text-sm font-semibold transition-colors duration-150 hover:text-primary ${
                    item.path == route.path ? 'text-primary active' : ''
                  }`"
                >
                  <div class="flex flex-col">
                    <router-link
                      class="w-full"
                      :to="{ name: item.name }"
                    >
                      <fa class="teste" :icon="`fa-solid fa-${item.icon}`" size="xl" />
                      <span class="md:ml-4 text-item">{{ item.label }}</span>
                    </router-link>
                  </div>
                </a>
              </li>
            </template>
          </ul>
        </template>
      </div>
    </div>
  </aside>
  </div>

  <div class="" v-if="isMobile === true">
  <div
    class="z-20 w-full sidebar overflow-x-auto shadow-sm bg-white dark:bg-gray-800"
  >
    <div
      class="text-gray-500 dark:text-gray-400 h-full bar2"
    >
      <div class="">
        <template v-if="Labels.length > 0">
          <ul class="">
            <template v-for="(item, index) in Labels" :key="index">
              <li v-if="item.show != false" class="px-6 my-2" :class="item.path === route.path && isMobile === true ? `border-b-4 border-pink-900` : ''">
                <span
                  class="bg-primary hover:text-primary"
                  aria-hidden="true"
                  v-if="item.path == route.path"
                ></span> 
                <a
                  :class="`inline-flex items-center w-full select-none text-sm font-semibold transition-colors duration-150 hover:text-primary ${
                    item.path == route.path ? 'text-primary active' : ''
                  }`"
                >
                  <div class="flex flex-col">
                    <router-link
                      class="w-full"
                      :to="{ name: item.name }"
                    >
                      <fa class="teste" :icon="`fa-solid fa-${item.icon}`" size="xl" />
                      <span class="md:ml-4 text-item">{{ item.label }}</span>
                    </router-link>
                  </div>
                </a>
              </li>
            </template>
          </ul>
        </template>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
// import  router  from "../../router";
import Labels from "../../router/labels.ts";
export default {
  name: "Sidebar",
  data()
  {
    return{
      isMobile: false
    }
  },
  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup() {
    const route = useRoute();
   
    const handleSubmenu = (item) => {
      if(document.getElementById(`list-${item}`).classList.contains("menu-closed")){
        document.getElementById(`list-${item}`).classList.remove("menu-closed");
        document.getElementById(`list-${item}`).classList.add("menu-open");
        document.getElementById(item).classList.remove("gg-chevron-down");
        document.getElementById(item).classList.add("gg-chevron-up");
      }else{
        document.getElementById(`list-${item}`).classList.remove("menu-open");
        document.getElementById(`list-${item}`).classList.add("menu-closed");
        document.getElementById(item).classList.add("gg-chevron-down");
        document.getElementById(item).classList.remove("gg-chevron-up");
      }
    };
    return { Labels, route, handleSubmenu };
  },
};
</script>

<style scoped>
.gg-dollar {
  margin-left: 5px;
}

.logo{
  height: 18%;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.menu-closed{
  display: none;
}

.menu-open{
  display: block;
}

@keyframes showlabels{
  0% {
    margin-top: -20px;
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.show-animation{
  animation-name: showlabels;
  animation-duration: 1s;
}

@media only screen and (max-width: 800px) {
  .sidebar {
    position: fixed;
    overflow-y: hidden;
    text-align: center;
    width: 100%;
    height: 15vh;
    background-color: #fbca01;
    bottom: 0;
    box-shadow: -5px 0px 20px #fbca01;

  }

  .menu-open{
    position: fixed;
    background-color: black;
    justify-content: center;
    width: 100vw;
    height: 90vh;
    left: 0;
    top: 0;
  }

  .menu-open div{
    margin-left: 0;
  }

  i:hover{
    color: black;
  }
  i{
    color: black;
  }

  .logo {
    display: none;
  }

  .list {
    display: flex;
    align-items: center;
  }

  span {
    margin-left: 0;
  }

  .bar {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bar2 {
    display: flex;
    align-items: center;
    justify-items: start;
  }

  .active {
    color: white;
  }

  i:hover {
    color: black;
  }

  .text-item {
    font-size: 12px;
  }

  .sidebar ul {
    margin-top: 15px;
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 100px;
  }

  .menu-open a{
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sidebar img {
    display: none;
  }
}
</style>