import store from '../../store/index'

export default {
    open(qrcodes){
        store.commit('modalQRCode',{
            display: true,
            qrcodes:qrcodes
        })
    },
    close(){
        store.commit('modalQRCode', {
            display: false,
            qrcodes:[]
        });
    },
}