import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex-mobile bg-gray-50"
}
const _hoisted_2 = { class: "sidebar mt-40" }
const _hoisted_3 = { class: "main p-8 container mx-auto" }
const _hoisted_4 = { class: "navbar" }
const _hoisted_5 = {
  key: 1,
  class: "grid overflow-x-auto bg-gray-50"
}
const _hoisted_6 = { class: "sidebar" }
const _hoisted_7 = { class: "main p-8 container mx-auto" }
const _hoisted_8 = { class: "navbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!
  const _component_modalConfirmation = _resolveComponent("modalConfirmation")!
  const _component_modalTrocaMotivo = _resolveComponent("modalTrocaMotivo")!
  const _component_loading = _resolveComponent("loading")!
  const _component_alertbox = _resolveComponent("alertbox")!
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_navbar = _resolveComponent("navbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_modal),
    _createVNode(_component_modalConfirmation),
    _createVNode(_component_modalTrocaMotivo),
    _createVNode(_component_loading),
    _createVNode(_component_alertbox),
    (_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_sidebar)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_view)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_navbar)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_sidebar)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_router_view)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_navbar)
          ])
        ]))
  ], 64))
}