import store from '../../store/index'

export default {
    open(value){
        store.commit('modalEmail',{
            display: true,
            tipoIngresso : value
        })
    },
    close(){
        store.commit('modalEmail', {
            display: false,
            tipoIngresso : ''
        });
    },
}