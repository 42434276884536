
import { useStore } from "vuex";


export default {
    name: "modalCarga",

    setup() {
        const store = useStore();


        const close = () => {
            store.commit("modalCarga", {
                display: false,
                carga: [],
                header: []
            });
        };



        return { store, close };
    }

}
