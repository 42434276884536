import store from "../../store/index";

export default {
  open(body: string, method: string) {
    store.commit("modalLogs", {
      display: true,
      body: body,
      method: method,
    });
  },
  close() {
    store.commit("modalLogs", {
      display: false,
    });
  },
};
