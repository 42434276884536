import store from "../../store/index";

export default {
  open(body: string, method: string, message: string) {
    console.log(method)
    store.commit("modalLogError", {
      display: true,
      body: body,
      method: method,
      message: message,
    });
  },
  close() {
    store.commit("modalLogError", {
      display: false,
    });
  },
};
