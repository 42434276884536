<template>
<div class="font-sans">
<modal />

<modalConfirmation />
<modalTrocaMotivo />
<modalEventoSantaFicha />
<modalEmail />
<modalQrcode />
<modalCarga />
<modalLogs/>
<modalLogError/>
<modalConfirmarCarga/>
<modalRelatorioCatraca/>
<modalPassaporte/>

<loading />

<alertbox />
    <router-view />
</div>
</template>

<script>
import { useRouter } from 'vue-router'

import Loading from './components/Loading/index'
import AlertBox from './components/Elements/AlertBox'
import Modal from './components/Elements/Modal'
import ModalConfirmation from './components/Elements/ModalConfirmacao.vue'
import ModalTrocaMotivo from './components/Elements/ModalTrocaMotivo.vue'
import ModalEventoSantaFicha from './components/Elements/ModalEventoSantaFicha.vue'
import ModalEmail from './components/Elements/ConfirmaEmail.vue'
import ModalQrcode from './components/Elements/ModalQcode.vue'
import ModalCarga from './components/Elements/ModalCarga.vue'
import ModalLogs from './components/Elements/ModalLogs.vue'
import ModalConfirmarCarga from './components/Elements/ModalConfirmarCarga.vue'
import ModalLogError from './components/Elements/ModalLogError.vue'
import ModalRelatorioCatraca from './components/Elements/ModalRelatorioCatraca.vue'
import ModalPassaporte from './components/Elements/ModalPassaporte.vue'

export default {
  name: 'App',
  components:{
    'alertbox': AlertBox,
    'loading': Loading,
    'modal': Modal,
    'modalConfirmation': ModalConfirmation,
    'modalTrocaMotivo': ModalTrocaMotivo,
    'modalEventoSantaFicha': ModalEventoSantaFicha,
    'modalEmail': ModalEmail,
    'modalQrcode': ModalQrcode,
    'modalCarga': ModalCarga,
    'modalLogs': ModalLogs,
    'modalConfirmarCarga': ModalConfirmarCarga,
    'modalLogError': ModalLogError,
    'modalRelatorioCatraca': ModalRelatorioCatraca,
    'modalPassaporte': ModalPassaporte
},
  setup(){
      const router = useRouter()
      const isAuth = localStorage.getItem('token_santo_cartao_adm') ? true : false
      if(!isAuth){
        router.push({ path: '/login' })
      }
  },
}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');




</style>

