import store from '../../store/index'

export default {
    open(id: string) {
        store.commit('modalRelatorioCatraca', {
            display: true,
            'id_evento': id,
        })
    },
    close() {
        store.commit('modalRelatorioCatraca', {
            display: false,
        });
    },
}