<template>
  <div
    v-if="store.getters.modalLogs.display"
    x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0"
    x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150"
    x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
  >
    <div
      x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2"
      x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150"
      x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      @keydown.escape="close"
      class="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog"
      id="modal"
    >
      <!-- Remove header if you don't want a close icon. Use modal body to place modal tile. -->
      <header class="flex justify-end">
        <button
          @click="close"
          class="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
          aria-label="close"
        >
          <svg
            class="w-4 h-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            role="img"
            aria-hidden="true"
          >
            <path
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
              fill-rule="evenodd"
            ></path>
          </svg>
        </button>
      </header>
      <!-- Modal body -->
      <div class="mt-4 mb-6">
        <!-- Modal title -->
        <p class="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300">
          Detalhes do Log
        </p>
        <p class="mb-2 text-sm font-normal">
          Método: {{ store.getters.modalLogs.method }}
        </p>
        <!-- Modal description -->
        <div class="w-full gap-4">
          <div
            class="w-full bg-black text-white p-1.5 mb-2 max-h-96 overflow-y-scroll"
          >
            <pre ref="bodyContent" class="break-words">
              {{ JSON.parse(store.getters.modalLogs.body, null, "\t") }}
            </pre>
          </div>
          <button
            class="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-primary border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-purple-600 hover:bg-primary focus:outline-none focus:shadow-outline-purple flex flex-row gap-2 sm:justify-center"
            @click="copyClipboard"
          >
            <i class="ml-3 font-medium gg-copy"></i> Copiar
          </button>
        </div>
      </div>
      <footer
        class="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800"
      >
        <button
          @click="close"
          class="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-primary border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-purple-600 hover:bg-primary focus:outline-none focus:shadow-outline-purple"
        >
          Fechar
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { defineComponent } from "vue";
import store from "store";
//import {jsonFormatter} from '@/services/formater'

export default defineComponent({
  name: "ModalLogs",
  props: {
    show: Boolean,
    method: { type: Function },
  },
  setup(props) {
    const store = useStore();
    //let formatted;

    const close = () => {
      store.commit("modalLogs", { display: false });
    };

    const copyClipboard = async () => {
      navigator.clipboard.writeText(store.getters.modalLogs.body);
      store.commit("alert", {
        display: true,
        title: "Copiado com sucesso!",
        message: "",
        type: "success",
      });
      setInterval(() => {
        store.commit("alert", {
          display: false,
        });
      }, 3000);
    };

    return { props, close, store, copyClipboard };
  },
});
</script>

<style scoped></style>
