import store from '../../store/index'

export default {
    open(id: string){
        store.commit('modalTrocaMotivo',{
            display: true,
            id: id,
        })
    },
    close(){
        store.commit('modalTrocaMotivo', {
            display: false,
        });
    },
}