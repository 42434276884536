
/* eslint-disable */
import { useStore } from "vuex";
import Button from '../Elements/Button.vue';
import { useRouter } from 'vue-router';
import { ref, watch } from "vue";

export default {
    name: "modalPassaporte",
    components: {
        Button,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const diasV = ref('');
        const configV = ref()
        const quantidadePorDia = ref({
            'Segunda': 1,
            'Terça': 1,
            'Quarta': 1,
            'Quinta': 1,
            'Sexta': 1,
            'Sábado': 1,
            'Domingo': 1
        });
        const diasExcluir = ref()

        // Array of days of the week
        const daysOfWeek = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];

        const somarQuantidade = (dia: any) => {
            quantidadePorDia.value[dia] += 1
        }

        const subtrairQuantidade = (dia: any) => {
            quantidadePorDia.value[dia] -= 1
        }

        const close = () => {
            store.commit("modalPassaporte", { display: false, callbackOK: () => { }, dias: "", config: "" });
        };

        const confirmar = async () => {

            if (diasV.value === 'Passaporte') {
                if (store.getters.modalPassaporte.callbackOK) {
                    store.getters.modalPassaporte.callbackOK(quantidadePorDia.value);
                }
            } else {
                if (store.getters.modalPassaporte.callbackOK) {
                    let configuracaoDiaUnico = {}

                    if (!diasExcluir.value) {
                        configuracaoDiaUnico = {
                            [diasV.value]: Number(quantidadePorDia.value[diasV.value])
                        }
                    } else {
                        configuracaoDiaUnico = {
                            [diasV.value]: Number(quantidadePorDia.value[diasV.value]),
                            ...diasExcluir.value
                        }
                    }

                    store.getters.modalPassaporte.callbackOK(configuracaoDiaUnico);
                }
            }

            diasV.value = ''
            configV.value = {}
            diasExcluir.value = {}
            quantidadePorDia.value = {
                'Segunda': 1,
                'Terça': 1,
                'Quarta': 1,
                'Quinta': 1,
                'Sexta': 1,
                'Sábado': 1,
                'Domingo': 1
            }
            store.commit("modalPassaporte", { display: false, callbackOK: () => { }, dias: "", config: "" });
        };

        watch(
            () => [store.getters.modalPassaporte.display],
            () => {
                if (store.getters.modalPassaporte.display) {
                    diasV.value = store.getters.modalPassaporte.dias;

                    configV.value = store.getters.modalPassaporte.config;

                    if (configV.value) {
                        daysOfWeek.map((x) => {
                            if (configV.value[x]) {
                                quantidadePorDia.value[x] = configV.value[x]
                                if (x !== diasV.value) {
                                    diasExcluir.value = {
                                        ...diasExcluir.value,
                                        [x]: -1
                                    }
                                }
                            }
                        })


                    }

                    if (diasV.value === undefined) {
                        store.commit("modalPassaporte", { display: false, callbackOK: () => { }, dias: "", config: "" });
                    }

                } else {
                    diasV.value = '';
                }
            }
        );

        return { store, close, confirmar, diasV, daysOfWeek, quantidadePorDia, somarQuantidade, subtrairQuantidade };
    },
};
