import axios from 'axios';
import router from '../router'
const isProd = process.env.NODE_ENV === 'production';
//const isProd = true
const instance = axios.create({
    baseURL: (isProd) ? 'https://api.santocartao.com.br/api' : 'http://localhost:3000/api',
    headers: {
        'Authorization': localStorage.getItem("token_santo_cartao_adm") ? `Bearer ${localStorage.getItem("token_santo_cartao_adm")}` : "",
    }
})


const GET = (url: string | void, timeout?: number) => {
    const config = {
      timeout: timeout || undefined // Definir o timeout se fornecido, caso contrário, deixa como undefined
    };
  
    return instance.get(url || "", config)
      .then(res => res.data)
      .then(res => {
        return res;
      })
      .catch(e => {
        if (e.response && (e.response.status === 403 || e.response.status === 401)) {
          router.push({ path: '/login' });
          localStorage.clear();
        }
        throw e.response.data.message;
      });
  };
  

const POST = (url: string, data: any, timeout?: number) => {
    const config = {
      timeout: timeout || undefined // Definir o timeout se fornecido, caso contrário, deixa como undefined
    };
      return instance.post(url, data, config).then(res => res.data).then(res => {
        return res
    }).catch(e => {
        if (e.response.status == 403 || e.response.status == 401) {
            router.push({ path: '/login' })
            localStorage.clear();
        }
        throw e.response.data.message
    })
}

const PUT = (url: string | void, params: object | Array<any> | void) => instance.put(url || "", params).then(res => res.data).then(res => {
    return res
}).catch(e => {
    if (e.response.status == 403 || e.response.status == 401) {
        router.push({ path: '/login' })
        localStorage.clear();
    }
    throw e.response.data.message
})

const DELETE = (url: string | void) => instance.delete(url || "").then(res => res.data).then(res => {
    return res
}).catch(e => {
    if (e.response.status == 403 || e.response.status == 401) {
        router.push({ path: '/login' })
        localStorage.clear();
    }
    throw e.response.data.message
})


const LOGIN = (route: string, payload: object | void): Promise<string> => instance.post(route, payload).then(res => res.data).then(res => {
    return res.token;
}).catch(e => {
    if (e.response.status == 403 || e.response.status == 401) {
        router.push({ path: '/login' })
        localStorage.clear();
    }
    throw e.response.data.message
})




//const isProd= false
const instanceApiVenda = axios.create({
    baseURL: (isProd) ? 'https://apivendas.santocartao.com.br/api' : 'http://127.0.0.1:7012/api',
    headers: {
        'Authorization': localStorage.getItem("token_santo_cartao_adm") ? `Bearer ${localStorage.getItem("token_santo_cartao_adm")}` : "",
        ['keyApiVendas']: '23tbOaox0uYsAO2pKziXQjqZc4TKsZwaLhsjy7JCkywDQZpDv6'
    },
})


const GET_VENDAS = (url: string | void) => instanceApiVenda.get(url || "").then(res => res.data).then(res => {
    return res
}).catch(e => {

    throw e.response.data.message
})

const POST_VENDAS = (url: string | void, params: object | Array<any | void> | void) => instanceApiVenda.post(url || "", params).then(res => res.data).then(res => {
    return res
}).catch(e => {

    throw e.response.data.message
})

const PUT_VENDAS = (url: string | void, params: object | Array<any> | void) => instanceApiVenda.put(url || "", params).then(res => res.data).then(res => {
    return res
}).catch(e => {

    throw e.response.data.message
})

const DELETE_VENDAS = (url: string | void) => instanceApiVenda.delete(url || "").then(res => res.data).then(res => {
    return res
}).catch(e => {

    throw e.response.data.message
})

export { GET, GET_VENDAS, POST, POST_VENDAS, PUT, PUT_VENDAS, DELETE, DELETE_VENDAS, LOGIN, instance };