<template>
  <div
    v-if="store.getters.modalEmail.display"
    x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0"
    x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150"
    x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
  >
    <div
      x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2"
      x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150"
      x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      @keydown.escape="() => console.log('asdas')"
      class="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog"
    >
      <div class="h-14">
        <h3>Reenvio de Email!</h3>
        <p>Para reenviar informe o email do cliente abaixo</p>
      </div>
      <div class="flex-1 my-3">
        <Input
          :label="'Email'"
          :placeholder="'Email do Cliente'"
          type="default"
          v-model="email"
        />
      </div>
      <div class="buttons">
        <Button @click="close" color="yellow-500" text="Sair" />
        <Button @click="confirm" color="primary" text="Confirmar" />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import {  ref } from "vue";
import { useRoute,useRouter } from "vue-router";
import Button from "./Button.vue";
import Input from "./Input.vue";
import { isEmpty } from "../../services/validate";
import {POST_VENDAS} from '../../services/api'

export default {
  name: "EmailConfirm",
  components: {
    Input,
    Button,
  },

  setup() {
    const store = useStore();
    const email = ref('');
    const route = useRoute()
    const router = useRouter()
    
    const close = ()=>{
      email.value = ''
      store.commit("modalEmail", { display: false });
    }

    const confirm = async ()=>{
      
      if(!email.value || email.value === '')
      {
        console.log(store.getters.modalEmail.tipoIngresso)

        store.commit("alert", { display: true,title:"Atenção!",message: 'Insira um email valido', type:"warning" });
      }else{
      try {
        store.commit("loading", true);
        
        const body = {
          'id_venda' : route.params.id,
          email : email.value
        }


        if(store.getters.modalEmail.tipoIngresso == 1)
        {
          await POST_VENDAS('/promoter_movements/reenvio/venda',{
            id:route.params.id
          });
        }else{
          await POST_VENDAS('/venda/reenvio',body);
        }
        

        store.commit("loading", false);
        store.commit("modalEmail", {display:false,tipoIngresso :''});

        email.value = ''

        store.commit("alert", { display: true,title:"Sucesso!",message: `email reenviado com sucesso`, type:"success" });

        router.push('/vendas')


      } catch (error) {
        store.commit("alert", { display: true,title:"Atenção!",message: `Algum erro ocorreu, tente novamente`, type:"warning" });
        store.commit("loading", false);
        email.value = ''
      }
      }
      
    }

    return {
      store,
      close,
      email,
      confirm
    };
  },
};
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  text-align: center;
  background-color: white;
  margin-top: 10%;
  padding: 0 0;
  border-radius: 20px;
}
.close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

.confirmar {
  width: 98%;
  display: inline-block;
  margin: 5px;
}

.buttons {
  display: flex;
  justify-content: space-around;
}

.msg {
  font-size: 12px;
  color: red;
}

.input {
  display: grid;

  margin-top: 25px;
  margin-bottom: 2px;
  width: 100%;
}
</style>