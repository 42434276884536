
import { useStore } from "vuex";

import { onMounted, ref, inject } from "vue";
import Button from "./Button.vue";
import Input from "./Input.vue";

import { GET, DELETE, POST } from "../../services/api";
import { useRoute, useRouter } from "vue-router";
import { isEmpty } from "../../services/validate";
import alert from "@/services/alert";

export default {
    name: "modalRelatorioCatraca",
    components: {
        Input,
        Button,
    },

    setup() {
        const store = useStore();
        const dataInicio = ref();
        const dataFim = ref();
        const route = useRoute();
        const loader: any = inject("loading");

        const close = () => {
            dataInicio.value = "";
            dataFim.value = "";
            store.commit("modalRelatorioCatraca", { display: false });
        };

        const confirmar = async () => {
            if (dataFim.value == "") {
                alert.open("Atenção!", "Selecione uma data final", "warning");
                dataFim.value = "";
                return;
            } else if (dataInicio.value == "" || dataInicio.value == null) {
                alert.open("Atenção!", "Selecione uma data de inicio", "warning");
                dataInicio.value = "";
                return;
            } else {

                try {
                    loader.open();
                    const body = {
                        "initDate": dataInicio.value,
                        "finalDate": dataFim.value,
                        "type": 3,
                        "id_evento": store.getters.modalRelatorioCatraca.id_evento
                    };

                    await POST('/catraca/relatorio', body)

                    store.commit("modalRelatorioCatraca", { display: false });

                    alert.open(
                        "Sucesso!",
                        `Relatorio recuperado com sucesso!`,
                        "success"
                    );

                    loader.close();
                } catch (e: any) {
                    console.log(e);
                    alert.open("Error", e, "error");
                    loader.close();
                }
            }
        };


        function getMinDate() {
            const aux = dataFim.value ? new Date(dataFim.value).getTime() : new Date().getTime()

            console.log(new Date(aux - 30 * 24 * 60 * 60 * 1000))
            return new Date(aux - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]

        }

        function getMaxDate() {
            const aux = dataInicio.value ? new Date(dataInicio.value).getTime() : new Date().getTime()

            console.log(new Date(aux + 30 * 24 * 60 * 60 * 1000))

            return new Date(aux + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]

        }

        return { store, close, dataInicio, confirmar, dataFim, getMinDate, getMaxDate };
    },
};
