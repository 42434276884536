<template>
  <div
    v-if="props.show"
    x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0"
    x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150"
    x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
  >
    <div
      x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2"
      x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150"
      x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      @keydown.escape="() => console.log('asdas')"
      class="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog"
    >
      <div id="header" class="flex justify-end">
        <fa :icon="`fa-solid fa-x`" size="xl" @click="close()" />
      </div>
      <div
        v-for="info in qrcode"
        :key="info.qrcode"
        class=""
      >
        <div class="flex justify-center">
          <div>
            <h4>Nome : {{ info.nome }}</h4>
            <qrcode :value="info.qrcode" />
            <h4>Tipo Ingresso : {{ info.detalhes }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref,onUpdated } from "vue";
export default {
  name: "modalQrcode",
  props:{
    show:Boolean,
    qrcode:Array,
    method: { type: Function },
  },

  setup(props) {
    const store = useStore();

    const qrcodes = ref(props.Qrcodes);

    const showModal = ref(props.show)

    onUpdated(()=>{
      console.log(props)
    })

    const close = () => {
      showModal.value = false
      props.method(false);
    };

    return { store, qrcodes,showModal,props,close };
  },
};
</script>

<style>
</style>