import { icon } from "@fortawesome/fontawesome-svg-core";

const List = [
  {
    icon: "house",
    label: "Dashboard",
    name: "Dashboard",
    path: "/",
    component: () => import("../views/Logs/index.vue"),
  },

  // Antiga dash

  {
    show: false,
    icon: "house",
    name: "Vendas/Últimas",
    path: "/vendas/ultimas",
    component: ()=> import("../views/Home.vue")
  },

  //Eventos
  {
    icon: "calendar",
    label: "Eventos",
    name: "Eventos",
    path: "/eventos",
    component: () => import("../views/Eventos/index.vue"),
  },
  {
    show: false,
    name: "Eventos/Criar",
    path: "/eventos/criar",
    component: () => import("../views/Eventos/create.vue"),
  },
  {
    show: false,
    name: "Eventos/Editar",
    path: "/eventos/editar/:id?",
    component: () => import("../views/Eventos/update.vue"),
  },
  {
    show: false,
    name: "Eventos/PosEvento",
    path: "/eventos/posevento/:id?",
    component: () => import("../views/Eventos/posevento.vue"),
  },

  // Tela de virada de lote
  {
    show: true,
    icon: "clock",
    label: "Agendamento",
    name: "Lote",
    path: "/lotes",
    component: () => import("../views/Lotes/index.vue"),
  },
  {
    show: false,
    icon: "math-percent",
    label: "Vendas",
    name: "Lote/Criar",
    path: "/lotes/criar",
    component: () => import("../views/Lotes/create.vue"),
  }, 
  {
    show: false,
    icon: "math-percent",
    label: "Vendas",
    name: "Lote/Editar",
    path: "/lotes/editar/:id?",
    component: () => import("../views/Lotes/update.vue"),
  },

  //Produtos
  {
    icon: "ticket",
    label: "Produtos",
    name: "Produtos",
    path: "/produtos/:id?",
    component: () => import("../views/Produtos/index.vue"),
  },
  {
    show: false,
    name: "Produtos/Criar",
    path: "/produtos/criar/:id?",
    component: () => import("../views/Produtos/create.vue"),
  },
  {
    show: false,
    name: "Produtos/Editar",
    path: "/produtos/editar/:id?",
    component: () => import("../views/Produtos/update.vue"),
  },
  //Setores
  {
    icon: "table",
    label: "Setores",
    name: "Setores",
    path: "/setores/",
    component: () => import("../views/Setores/index.vue"),
  },
  {
    show: false,
    label: "Setores",
    name: "Setores/Editar",
    path: "/setores/editar/:id?",
    component: () => import("../views/Setores/update.vue"),
  },
  {
    show: false,
    label: "Setores",
    name: "Setores/Criar",
    path: "/setores/criar/",
    component: () => import("../views/Setores/create.vue"),
  },
  //Variação
  {
    icon: "network-wired",
    label: "Variação",
    name: "Variação",
    path: "/variacao/",
    component: () => import("../views/Variacao/index.vue"),
  },
  {
    show: false,
    label: "Variação",
    name: "Variação/Editar",
    path: "/variacao/editar/:id?",
    component: () => import("../views/Variacao/update.vue"),
  },
  {
    show: false,
    label: "Variacao",
    name: "Variação/Criar",
    path: "/variacao/criar/",
    component: () => import("../views/Variacao/create.vue"),
  },
  //TipoProduto
  {
    icon: "tag",
    label: "Tipo Produto",
    name: "Tipo Produto",
    path: "/tipo_produto",
    component: () => import("../views/TiposProduto/index.vue"),
  },
  {
    show: false,
    label: "Tipo Produto",
    name: "Tipo Produto/Editar",
    path: "/tipo_produto/editar/:id?",
    component: () => import("../views/TiposProduto/update.vue"),
  },

  //SubTipoProduto
  {
    icon: "s",
    label: "Sub Tipo Produto",
    name: "Sub Tipo Produto",
    path: "/subtipoproduto",
    component: () => import("../views/SubTipoProduto/index.vue"),
  },
  {
    show: false,
    label: "Sub Tipo Produto",
    name: "Sub Tipo Produto/Criar",
    path: "/subtipoproduto/criar",
    component: () => import("../views/SubTipoProduto/create.vue"),
  },
  {
    show: false,
    label: "Sub Tipo Produto",
    name: "Sub Tipo Produto/Editar",
    path: "/subtipoproduto/editar/:id?",
    component: () => import("../views/SubTipoProduto/update.vue"),
  },

  //Usuarios
  {
    icon: "user",
    label: "Usuários",
    name: "Usuários",
    path: "/usuarios",
    component: () => import("../views/Usuarios/index.vue"),
  },
  {
    show: false,
    label: "Usuários",
    name: "Usuários/Criar",
    path: "/usuarios/criar",
    component: () => import("../views/Usuarios/create.vue"),
  },
  {
    show: false,
    label: "Usuários",
    name: "Usuários/Editar",
    path: "/usuarios/editar/:id?",
    component: () => import("../views/Usuarios/update.vue"),
  },

  //Vinculos
  {
    show: false,
    label: "Vínculos",
    name: "Vínculos",
    path: "/vinculos",
    component: () => import("../views/Vinculos/index.vue"),
  },
  {
    show: false,
    label: "Vínculos",
    name: "Vínculos/Criar/Usuario",
    path: "/vinculos/criar",
    component: () => import("../views/Vinculos/create.vue"),
  },

  //Promoters
  {
    icon: "user-tie",
    label: "Promoters",
    name: "Promoters",
    path: "/promoter",
    component: () => import("../views/Promoters/index.vue"),
  },
  {
    show: false,
    label: "Promoters",
    name: "Promoters/Criar",
    path: "/promoter/criar",
    component: () => import("../views/Promoters/create.vue"),
  },
  {
    show: false,
    label: "Promoters",
    name: "Promoters/Editar",
    path: "/promoter/editar/:id?",
    component: () => import("../views/Promoters/update.vue"),
  },
  {
    show: false,
    label: "Promoters",
    name: "Promoters/Editar_V2",
    path: "/promoter/editar_v2/:id?",
    component: () => import("../views/Promoters/update_v2.vue"),
  },

  //Financeiro
  {
    icon: "money-bill",
    label: "Financeiro",
    name: "Financeiro",
    path: "/financeiro/:id_evento?",
    component: () => import("../views/Financeiro/index.vue"),
  },
  {
    show: false,
    label: "Financeiro",
    name: "Financeiro/Criar",
    path: "/financeiro/criar/:id_evento?",
    component: () => import("../views/Financeiro/create.vue"),
  },
  {
    show: false,
    label: "Financeiro",
    name: "Financeiro/CriarManual",
    path: "/financeiro/criarManual/:id_evento?",
    component: () => import("../views/Financeiro/createManual.vue"),
  },
  {
    show: false,
    label: "Financeiro",
    name: "Financeiro/Editar",
    path: "/financeiro/editar/:id?",
    component: () => import("../views/Financeiro/update.vue"),
  },

  /*
   //Relatórios
   { icon: 'file-document', label: 'Relatórios', name:"Relatórios", path: '/relatorios',  component: () => import('../views/Relatorios/index.vue') },
   { show: false, icon: 'file-document', label: 'Relatórios', name:"Relatórios/Criar", path: '/relatorios/criar',  component: () => import('../views/Relatorios/create.vue') },
   { show: false, icon: 'file-document', label: 'Relatórios', name:"Relatórios/Editar", path: '/relatorios/editar/:id?',  component: () => import('../views/Relatorios/update.vue') },
  */

  //Logs
  {
    show: false,
    icon: "file-excel",
    label: "Logs",
    name: "Logs",
    path: "/logs",
    component: () => import("../views/Logs/index.vue"),
  },

  //Vendas
  {
    icon: "percent",
    label: "Vendas",
    name: "Vendas",
    path: "/vendas",
    component: () => import("../views/Vendas/index.vue"),
  },
  {
    show: false,
    icon: "math-percent",
    label: "Vendas",
    name: "Vendas/Criar",
    path: "/vendas/criar",
    component: () => import("../views/Vendas/create.vue"),
  },
  {
    show: false,
    icon: "math-percent",
    label: "Vendas",
    name: "Vendas/Editar",
    path: "/vendas/editar/:id?",
    component: () => import("../views/Vendas/update.vue"),
  },

  // Pedidos
  {
    show: false,
    icon: "shopping-cart",
    label: "Buscar Pedidos",
    name: "Buscar Pedidos",
    path: "/pedidos",
    component: () => import("../views/Pedidos/index.vue"),
  }
];

export default List;
