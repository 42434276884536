import store from '../../store/index'
/* eslint-disable */

export default {
    open(callback=() => {''}, dias="", config=""){
        store.commit('modalPassaporte',{
            display: true,
            dias: dias,
            config:config,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalPassaporte', {
            display: false,
            dias: "",
            config:"",
            callbackOK: () => {}
        });
    },
}