import store from '../../store/index'

export default {
    open(callbackOK: Function, callbackCancel: Function | void) {
        store.commit('modalConfirmarCarga', {
            display: true,
            callbackOK: callbackOK,
            callbackCancel: callbackCancel
        });
    },
    close() {
        store.commit('modalConfirmarCarga', {
            display: false,
        });
    },
}