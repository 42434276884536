import store from '../../store/index'

export default {
    open(){
        store.commit('modalEventoSantaFicha',{
            display: true
        })
    },
    close(){
        store.commit('modalEventoSantaFicha', {
            display: false,
        });
    },
}