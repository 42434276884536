<template>
   <div
    v-if="store.getters.modalEventoSantaFicha.display"
    x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0"
    x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150"
    x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
  >
    <div
      x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2"
      x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150"
      x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      @keydown.escape="close()"
      class="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog"
    >
      <div class="modal">
      <p>Insira no Campo abaixo o ID de integração do Evento com o Santa Ficha</p>
      </div>

      <div class="confirmar">
          <div>
            <Input
              type="default"
              id="santaficha"
              v-model="santaficha"
              label="Evento Santa Ficha"
              placeholder="Id do Evento do Santa Ficha"    
            />
          </div>
          <div class="buttons">
            <Button @click="close" color="yellow-500" text="Sair" />
            <Button @click="confirmar()" color="primary" text="Confirmar" />
          </div>
        </div>
    </div>
  </div>

</template>

<script>
import { useStore } from "vuex";
import Button from "./Button.vue";
import Input from "./Input.vue";
import { POST } from "../../services/api";
import { useRouter, useRoute } from "vue-router";
import {  ref } from "vue";

export default {
    name: "modalEventoSantaFicha",
    components: {
    Input,
    Button,
    },

setup(){
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const santaficha = ref('')

    const close = () => {
      santaficha.value = ""; 
      const currentRoute = router.currentRoute.value;
      const newQuery = { ...currentRoute.query, pag: 2 };
      router.push({ path: currentRoute.path, query: newQuery });
      store.commit("modalEventoSantaFicha", { display: false });
    };

    const confirmar = async() =>{
      if(santaficha.value != "")
      {
        try {
          store.commit("loading", true);
          const body = {
            'id_santa_ficha' : santaficha.value,
            'id_evento' : route.params.id
          }
          
          const data = {
          idEvento: route.params.id,
          type: 'santa_ficha',
          acao: true
          };

          const response = await POST(`event/funcionalidade`,
            data
          );
          
          await POST(`evento_santaficha`,body)
          santaficha.value = "";

          store.commit("alert", { display: true,title:"Atenção!",message: `Santa_ficha foi ativado com sucesso`, type:"success" });
           
          store.commit("modalEventoSantaFicha", { display: false });
          store.commit("loading", false);
          //router.push("/eventos");
        } catch (error) {
            santaficha.value = "";
          store.commit("alert", { display: true,title:"Atenção!",message: `Algum erro ocorreu, tente novamente`, type:"warning" });
          store.commit("loading", false);
        }

      }else{
        alert('Informe um ID valido.')
      }
    }


    return {
        store,
        close,
        santaficha,
        confirmar,
    }
}


}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  text-align: center;
  background-color: white;
  border-radius: 20px;
}
.close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

.confirmar {
  width: 98%;
  display: inline-block;
  padding: auto;
  margin: 15px;
}

.buttons {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
}

.msg {
  font-size: 12px;
  color: red;
}

.input {
  display: grid;

  margin-top: 25px;
  margin-bottom: 2px;
  width: 100%;
}
</style>
