
import { defineComponent } from 'vue';
import Sidebar from '../components/Elements/Sidebar.vue'
import Navbar from '../components/Elements/Navbar.vue'


export default defineComponent({
  name: 'App',
  components: {
    'sidebar': Sidebar,
    'navbar': Navbar,
  },
  data()
  {
    return{
      isMobile: false
    }
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
});
